import React from "react"

import Layout from "../../../components/Layout/layout"
import SEO from "../../../components/SEO/seo"
import HeroInner from "../../../components/HeroInner/hero-inner"
import ArticleFullWidth from "../../../components/ArticleFullWidth/article-full-width"
import Gallery from "../../../components/Gallery/gallery"

const HomeDecor = () => (
  <Layout>
    <SEO title="Home Decor" />
    <HeroInner title="Home Decor" imageAlt="Home Decor" imagePath="services/retail-products/home-decor/hero.jpg" />
    <ArticleFullWidth
      descriptions={[
        `Customers are becoming proactive home décor shoppers who get an endless supply of inspiration online.`,
        `The home décor industry was worth 582 billion dollars in 2017, according to Research Data. Thanks to rising income levels, recovering real estate industry, and increasing globalization, it’s projected to exceed 741 billion dollars by 2023, with a CAGR of around 4% during 2018-2023.`,
        `The market is expected to account for 14.4% of total retail e-commerce sales in 2023.`,
        `We Provide following home décor Categories to the retailers worldwide. We can also custom design any products in this category as per customer’s demand.`,
      ]}
    />
    <Gallery
      items={[
        {
          name: "Furniture",
          imagePath: "services/retail-products/home-decor/furniture.jpg",
        },
        {
          name: "Rugs",
          imagePath: "services/retail-products/home-decor/bedding.jpg",
        },
        {
          name: "Wall Décor",
          imagePath: "services/retail-products/home-decor/wall-decor.jpg",
        },
        {
          name: "Décor Collection",
          imagePath: "services/retail-products/home-decor/decor-collection.jpg",
        },
        {
          name: "Storage and Organization",
          imagePath: "services/retail-products/home-decor/storagr-and-organization.jpg",
        },
        {
          name: "Pillow",
          imagePath: "services/retail-products/home-decor/pillow.jpg",
        },
        {
          name: "Kitchen & Dining",
          imagePath: "services/retail-products/home-decor/kitchen-and-dining.jpg",
        },
        {
          name: "Bedding",
          imagePath: "services/retail-products/home-decor/bedding.jpg",
        },
        {
          name: "Lamps & Lighting",
          imagePath: "services/retail-products/home-decor/lamp-and-lighting.jpg",
        },
        {
          name: "Bath",
          imagePath: "services/retail-products/home-decor/bath.jpg",
        },
      ]}
    />
  </Layout>
)

export default HomeDecor
